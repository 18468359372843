











































import AzureAuth from '@/plugins/AzureAuth';
import Vue from 'vue';
export default Vue.extend({
  data: () => ({
    items: [
      {
        title: 'Learning App',
        href: process.env.VUE_APP_LEARNING_URL,
        icon: '/icons/icon_learningapp.svg',
      },
      {
        title: 'Communicator',
        href: process.env.VUE_APP_COMMUNICATOR_URL,
        icon: '/icons/icon_communicator.svg',
      },
      {
        title: 'Smartboard',
        href: process.env.VUE_APP_SMARTBOARD_URL,
        icon: '/icons/icon_smartboard.svg',
      },
      {
        title: 'MyTrailMap',
        href: process.env.VUE_APP_PROSYS_URL,
        icon: '/icons/prosys_logo.svg',
      },
    ],
    isAdmin: false,
  }),
  methods: {
    logout() {
      AzureAuth.logout();
    },
  },
  mounted() {
    const stringUser = localStorage.getItem('user');
    if (stringUser) {
      try {
        const user = JSON.parse(stringUser);
        this.isAdmin = user.permissions.includes('CAN_USE_ILP_ADMIN_PANEL');
      } catch {
        this.isAdmin = false;
      }
    }
  },
});
